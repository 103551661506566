import {Button, Form, message, Popconfirm, Select, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {useHistory} from 'react-router-dom';
import {FaPlusCircle, FaSearchPlus} from 'react-icons/fa';
import {HELPER} from '../../helper';

export default function CarModelList() {
  const [items, setItems] = useState([]);
  const [carCompanies, setCarCompanies] = useState([]);

  const [searchedData, setSearchedData] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('descend');

  const history = useHistory();

  async function handleDelete(id) {
    Api.init().delete('/car-models/' + id).then(() => {
      message.success('Xóa thành công');
      getData({
        page: 1,
        orderBy: sortField,
        orderType: sortOrder,
        carCompany: searchedData['car_company'] || null,
      });
    }).catch(() => {
      message.error('Xóa thất bại, vui lòng thử lại!');
    }).finally(() => {
      setIsLoading(false);
    });
  }

  const columns = [
    {
      title: 'Tên hãng xe',
      dataIndex: 'car_company',
      key: 'car_company',
      sorter: true,
      render: (_, record) => {
        return record['car_company'].name;
      },
    },
    {
      title: 'Tên dòng xe',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return new Date(record['createdAt']).toLocaleString();
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/car-models/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Bạn chắc chắn muốn xóa chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    Api.init().get('/car-models', {params}).then(res => {
      setItems(res.data.data);
      console.log('DEBUG res.data.count', res.data.count);
      setTotalItems(res.data.total);
    }).catch((err) => {
      HELPER.handleApiError(err)
    });
  }

  async function getCarCompanies() {
    try {
      const res = await Api.init().get('/car-companies', {
        itemPerPage: 1000,
      });

      setCarCompanies(res.data.data);
    } catch (e) {
      HELPER.handleApiError(e)
    }
  }

  useEffect(() => {
    (async function() {
      await getCarCompanies();
    })();
  }, []);

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    console.log('DEBUG handleTableChange', {
      pagination,
      filters,
      sorter,
    });
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
      carCompany: searchedData['car_company'] || null,
    });
  };

  const [form] = Form.useForm();

  useEffect(() => {
    getData({
      page: currentPage,
      orderBy: sortField,
      orderType: sortOrder,
      carCompany: searchedData['car_company'] || null,
    });
  }, [searchedData]);

  function onFinishSearch(values) {
    setSearchedData({
      ...values,
    });
  }

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
          <Button onClick={() => {
            history.push(`/car-models/edit?carCompany=${searchedData.car_company || ''}`);
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaPlusCircle/>
              <div className={'ml-3'}>Tạo mới</div>
            </div>
          </Button>
        </div>

        <div className={'p-5 bg-white mt-5'}>

          <div className={'mb-5'} style={{
            // width: '400px'
          }}>
            <Form
                initialValues={{
                  car_company: searchedData.car_company || '',
                }}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                layout={'horizontal'}
                form={form}
                onFinish={onFinishSearch}
            >
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <Form.Item name={'car_company'} label="Hãng xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option key={'all'} value={''}>Tất cả</Select.Option>
                      {carCompanies.map(item => {
                        return (
                            <Select.Option key={item.id} value={item.id}>{item.name}</Select.Option>
                        );
                      })}
                      {/*{searchedData.car_company && carCompanies && !carCompanies.map(item => String(item.id)).includes(String(searchedData.car_company)) && (*/}
                      {/*    <Select.Option key={'delete'} value={searchedData.car_company}>{'Hãng xe đã bị khóa, vui lòng chọn lại'}</Select.Option>*/}
                      {/*)}*/}
                    </Select>
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <Button block htmlType={'submit'} type="primary" className={'!bg-green-600 !border-green-600'}>
                    <div className={'flex space-between items-center justify-center'}>
                      <FaSearchPlus/>
                      <div className={'ml-1'}>Tìm kiếm</div>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>


          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
