import {Button, DatePicker, Form, Input, message, Modal, Popconfirm, Select, Space, Table} from 'antd';
import {useEffect, useRef, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {useHistory} from 'react-router-dom';
import {FaDownload, FaPlusCircle, FaSearchPlus, FaTools} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';
import moment from 'moment';
import {HELPER} from '../../helper';

const XLSX = require('xlsx');

export default function ContractList() {
  const [items, setItems] = useState([]);
  const [carModels, setCarModels] = useState([]);
  const [staffs, setStaffs] = useState([]);
  const [currentFilterParams, setCurrentFilterParams] = useState({});

  const query = useHookQuery();

  // First time call data
  useEffect(() => {
    const queryParams = {};
    query.forEach((value, key) => {
      if (['civil_insurance_expire_date_for_search', 'contract_start_date', 'contract_end_date'].includes(key)) {
        value = value.split(',').map(item => moment(item));
      }

      queryParams[key] = value;
    });
    form.setFieldsValue(queryParams);
    setCurrentFilterParams(queryParams);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...JSON.parse(JSON.stringify(queryParams)),
    });
  }, []);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortField, setSortField] = useState('createdAt');
  const [sortOrder, setSortOrder] = useState('descend');
  const [importItems, setImportItems] = useState([]);

  const history = useHistory();

  async function handleDelete(id) {
    Api.init().delete('/vehicles/' + id).then(() => {
      message.success('Xóa thành công');
      getData({
        page: 1,
        orderBy: sortField,
        orderType: sortOrder,
        ...currentFilterParams,
      });
    }).catch((err) => {
      HELPER.handleApiError(err);
    });
  }

  const columns = [
    {
      title: 'Biển số',
      dataIndex: 'license_plate',
      key: 'license_plate',
      sorter: true,
    },
    {
      title: 'Số khung',
      dataIndex: 'frame_number',
      key: 'frame_number',
      sorter: true,
    },
    {
      title: 'Số máy',
      dataIndex: 'machine_number',
      key: 'machine_number',
      sorter: true,
    },
    {
      title: 'Trạng thái xe',
      dataIndex: 'status',
      key: 'status',
      sorter: true,
      render: (_, record) => {
        return CONFIG.mappingStatusToLabel[record['status']];
      },
    },
    {
      title: 'Trạng thái HĐ',
      dataIndex: 'contract_status',
      key: 'contract_status',
      sorter: true,
      render: (_, record) => {
        return CONFIG.mappingStatusToLabel[record['contract_status']];
      },
    },
    {
      title: 'Hạn BH dân sự',
      dataIndex: 'civil_insurance_expire_date',
      key: 'civil_insurance_expire_date',
      sorter: true,
      render: (_, record) => {
        if (record['civil_insurance_expire_date']) {
          return moment(record['civil_insurance_expire_date']).format('DD-MM-YYYY').valueOf();
        }
      },
    },
    {
      title: 'Ngày kết thúc HĐ',
      dataIndex: 'contract_end_date',
      key: 'contract_end_date',
      sorter: true,
      render: (_, record) => {
        if (record['contract_end_date']) {
          return moment(record['contract_end_date']).format('DD-MM-YYYY').valueOf();
        }
      },
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return new Date(record['createdAt']).toLocaleString();
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/vehicles/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Bạn chắc chắn muốn xóa chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    Api.init().get('/vehicles', {params}).then(res => {
      setItems(res.data.data);
      setTotalItems(res.data.total);
    }).catch((err) => {
      HELPER.handleApiError(err);
    });
  }

  function getCarModels() {
    Api.init().get('/car-models', {
      itemPerPage: 1000,
    }).then(res => {
      setCarModels(res.data.data);
    }).catch((err) => {
      HELPER.handleApiError(err);
    });
  }

  function getStaffs() {
    Api.init().get('/staffs', {
      itemPerPage: 1000,
    }).then(res => {
      setStaffs(res.data.data);
    }).catch((err) => {
      HELPER.handleApiError(err);
    });
  }

  useEffect(() => {
    getCarModels();
    getStaffs();
  }, []);

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
      ...currentFilterParams,
    });
  };

  const [form] = Form.useForm();

  function onFinishSearch(values) {
    const clone = JSON.parse(JSON.stringify(values));

    Object.keys(clone).forEach(key => {
      if (clone[key] === undefined) {
        clone[key] = '';
      }
    });

    ['civil_insurance_expire_date_for_search'].forEach(key => {
      if (clone[key] && clone[key].length) {
        try {
          clone[key][0] = moment(clone[key][0]).set({year: CONFIG.specialYear, hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
          clone[key][1] = moment(clone[key][1]).set({year: CONFIG.specialYear, hour: 23, minute: 59, second: 59, millisecond: 0}).toISOString();
        } catch (e) {
          console.log('DEBUG E', e);
        }
      }
    });

    ['contract_start_date', 'contract_end_date'].forEach(key => {
      if (clone[key] && clone[key].length) {
        try {
          clone[key][0] = moment(clone[key][0]).set({hour: 0, minute: 0, second: 0, millisecond: 0}).toISOString();
          clone[key][1] = moment(clone[key][1]).set({hour: 23, minute: 59, second: 59, millisecond: 0}).toISOString();
        } catch (e) {
          console.log('DEBUG E', e);
        }
      }
    });

    setCurrentFilterParams(clone);
    const url = new URLSearchParams(clone);
    history.push(`/vehicles?${url}`);

    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...clone,
    });
  }

  const inputExel = useRef(null);
  const formExel = useRef(null);

  useEffect(() => {
    if (!carModels || !carModels.length) {
      return;
    }

    inputExel.current.addEventListener('change', event => {
      event.preventDefault();
      const input = inputExel.current.files[0];
      const reader = new FileReader();

      reader.onload = function(e) {
        const data = e.target.result;

        setTimeout(() => {
          formExel.current.reset();
        }, 1000);

        const workbook = XLSX.read(data, {
          type: 'binary',
        });

        workbook.SheetNames.forEach(function(sheetName) {
          // Here is your object
          let items = XLSX.utils['sheet_to_row_object_array'](workbook.Sheets[sheetName]);
          items = items.map(item => {
            if (item.registration_date_1) {
              item.registration_date_1 = moment(item.registration_date_1, 'DD-MM-YYYY').toISOString();
            }

            if (item.car_model) {
              const matched = carModels.find(model => model.name === item.car_model);
              item.car_model_id = matched ? matched.id : null;
            }

            return item;
          });
          setImportItems(items);
        });
      };

      reader.readAsBinaryString(input);
    });
  }, [carModels]);

  const exportImportResults = () => {
    const ws = XLSX.utils.json_to_sheet(importItems);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'IMPORT_RESULTS');
    XLSX.writeFileXLSX(wb, 'IMPORT_RESULTS.xlsx');
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isImportFinished, setIsImportFinished] = useState(false);
  const [importingIndex, setImportingIndex] = useState(null);
  const [importSuccess, setImportSuccess] = useState([]);
  const [importError, setImportError] = useState({});

  function genImportColumns() {
    return [
      {
        title: 'Biển số',
        dataIndex: 'license_plate',
        key: 'license_plate',
      },
      {
        title: 'Số khung',
        dataIndex: 'frame_number',
        key: 'frame_number',
      },
      {
        title: 'Số máy',
        dataIndex: 'machine_number',
        key: 'machine_number',
      },
      {
        title: 'Dòng xe',
        dataIndex: 'car_model',
        key: 'car_model',
        render: (_, record) => {
          for (let i = 0; i < carModels.length; i++) {
            if (String(carModels[i].id) === String(record.car_model_id)) {
              return carModels[i].name;
            }
          }

          return <span className={'text-red-500'}>{record.car_model}</span>;
        },
      },
      {
        title: 'Tên chủ xe',
        dataIndex: 'owner_name',
        key: 'owner_name',
      },
      {
        title: 'Địa chỉ chủ xe',
        dataIndex: 'owner_address',
        key: 'owner_address',
      },
      {
        title: 'SĐT',
        dataIndex: 'phone_number_1',
        key: 'phone_number_1',
      },
      {
        title: 'Ngày đăng kí xe',
        dataIndex: 'registration_date_1',
        key: 'registration_date_1',
        render: (_, record) => {
          if (record['registration_date_1']) {
            return moment(record['registration_date_1']).format('DD-MM-YYYY').valueOf();
          }
        },
      },
      {
        title: 'Ghi chú',
        dataIndex: 'note',
        key: 'note',
      },
      {
        title: 'Trạng thái import',
        key: 'action',
        width: 150,
        render: (_, record, index) => {
          if (index === importingIndex) {
            return 'Đang nhập';
          }

          if (importSuccess.includes(index)) {
            return 'Thành công';
          }

          if (importError[index]) {
            return importError[index];
          }
        },
      },
    ]
  }

  const [importColumns, setImportColumns] = useState(genImportColumns());

  useEffect(() => {
    setImportColumns(genImportColumns())
  }, [importError, importingIndex, importSuccess, carModels])

  async function onHandleImportData() {
    const ids = [];
    const errors = {};
    for (let i = 0; i < importItems.length; i++) {
      const todoImport = importItems[i];
      setImportingIndex(i);
      await new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 50);
      });
      try {
        await Api.init().post('/vehicles', {
          ...todoImport,
          car_model: todoImport.car_model_id,
          contract_status: 'NEW',
          status: 'APPROVED',
        });
        ids.push(i);
        setImportSuccess(ids);
        setImportingIndex(null);
      } catch (e) {
        setImportingIndex(null);
        errors[i] = 'Lỗi import';
        setImportError(errors);
      }
    }
    message.success('Import thành công');
    // setImportItems([]);
    // setIsModalOpen(false);

    setIsImportFinished(true)

    // Reload data
    getData({
      page: 1,
      orderBy: sortField,
      orderType: sortOrder,
      ...currentFilterParams,
    });
  }

  useEffect(() => {
    if (importItems && importItems.length) {
      setIsModalOpen(true);
    }
  }, [importItems]);

  return (
      <div className={'p-5'}>
        <Modal
            maskClosable={false}
            keyboard={false}
            hideOk
            okButtonProps={{ style: { display: isImportFinished ? 'none' : null } }}
            width={'100%'} okText={'Bắt đầu nhập dữ liệu'} cancelText={isImportFinished ? 'Đóng' : 'Hủy'} title="Vui lòng kiểm tra dữ liệu trước khi import"
            open={isModalOpen}
            onOk={onHandleImportData}
            onCancel={() => {
              setIsImportFinished(false)
              setIsModalOpen(false);
              setImportItems([]);
              setImportError({});
              setImportSuccess([]);
              setImportingIndex(null);
            }}
        >
          {importItems && importItems.length && (
              <Table
                  rowKey={'id'}
                  size={'small'}
                  dataSource={importItems}
                  columns={importColumns}
                  pagination={{
                    position: ['topCenter', 'bottomCenter'],
                    pageSize: 100000000,
                    // total: totalItems,
                    // size: 'small',
                    // current: currentPage,
                    // onChange: (page, pageSize) => {
                    //   setCurrentPage(page);
                    // },
                  }}
              />
          )}
        </Modal>

        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
          <div className="right">
            <Button onClick={() => {
              window.open('/SAMPLE_IMPORT.xlsx', '_new');
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600 mr-5'}>
              <div className={'flex space-between items-center'}>
                <FaDownload/>
                <div className={'ml-3'}>Tải file import mẫu</div>
              </div>
            </Button>

            <Button onClick={() => {
              inputExel.current.click();
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600 mr-5'}>
              <div className={'flex space-between items-center'}>
                <FaTools/>
                <div className={'ml-3'}>Nhập từ Exel</div>
              </div>

              <form ref={formExel}>
                <input style={{display: 'none'}} ref={inputExel} type="file" accept={'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel'}/>
              </form>
            </Button>
            <Button onClick={() => {
              history.push(`/vehicles/edit`);
            }} type={'primary'}
                    className={'!bg-green-600 !border-green-600'}>
              <div className={'flex space-between items-center'}>
                <FaPlusCircle/>
                <div className={'ml-3'}>Tạo mới</div>
              </div>
            </Button>
          </div>
        </div>

        <div className={'p-5 bg-white mt-5'}>

          <div className={'mb-5'}>
            <Form
                initialValues={{
                  status: '',
                  contract_status: '',
                  car_model: '',
                }}
                labelCol={{span: 24}}
                wrapperCol={{span: 24}}
                layout={'horizontal'}
                form={form}
                onFinish={onFinishSearch}
            >
              <div className="grid grid-cols-4 gap-4">
                <div>
                  <Form.Item name={'license_plate'} label="Biển số">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'frame_number'} label="Số khung">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'machine_number'} label="Số máy">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'phone_number'} label="Số điện thoại">
                    <Input/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'status'} label="Trạng thái xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                      <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                      <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                      <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'contract_status'} label="Trạng thái HĐ">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      <Select.Option value={'DRAFT'}>Bản nháp</Select.Option>
                      <Select.Option value={'WAIT_APPROVAL'}>Chờ phê duyệt</Select.Option>
                      <Select.Option value={'REJECTED'}>Đã từ chối</Select.Option>
                      <Select.Option value={'APPROVED'}>Đã phê duyệt</Select.Option>
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'related_staff'} label="Nhân viên tạo xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                        defaultValue={''}
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {staffs.map(item => {
                        return (
                            <Select.Option key={item.id} value={String(item.id)}>{item.name} - {item.code || ''}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name={'car_model'} label="Dòng xe">
                    <Select
                        showSearch
                        optionFilterProp="children"
                    >
                      <Select.Option value={''}>Tất cả</Select.Option>
                      {carModels.map(item => {
                        return (
                            <Select.Option key={item.id} value={String(item.id)}>{item.car_company.name} - {item.name}</Select.Option>
                        );
                      })}
                    </Select>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item name="civil_insurance_expire_date_for_search" label={'Hạn bảo hiểm dân sự'}>
                    <DatePicker.RangePicker format={'DD-MM'} style={{width: '100%'}}/>
                  </Form.Item>
                </div>

                <div>
                  <Form.Item name="contract_start_date" label={'Ngày bắt đầu HĐ trong khoảng'}>
                    <DatePicker.RangePicker format={'DD-MM-YYYY'} style={{width: '100%'}}/>
                  </Form.Item>
                </div>
                <div>
                  <Form.Item name="contract_end_date" label={'Ngày kết thúc HĐ trong khoảng'}>
                    <DatePicker.RangePicker format={'DD-MM-YYYY'} style={{width: '100%'}}/>
                  </Form.Item>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-4">
                <div></div>
                <div></div>
                <div></div>
                <div>
                  <Button block htmlType={'submit'} type="primary" className={'!bg-green-600 !border-green-600'}>
                    <div className={'flex space-between items-center justify-center'}>
                      <FaSearchPlus/>
                      <div className={'ml-1'}>Tìm kiếm</div>
                    </div>
                  </Button>
                </div>
              </div>
            </Form>
          </div>


          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
