import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Button, Form, Input, message} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {Api} from '../services/api';

export default function PageLogin() {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});
  const [previousFormValues, setPreviousFormValues] = useState({});
  const [step, setStep] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [form] = useForm();

  useEffect(() => {
  }, [params.id]);

  const onFinish = async (values) => {
    if (step === 1) {
      await checkUser();
    } else {
      await loginUser();
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  async function checkUser() {
    const values = form.getFieldsValue();
    console.log('DEBUG values', values);
    try {
      await Api.init().post('/accounts/admin-check', {
        ...values,
        role: 'MANAGER',
      });
      setPreviousFormValues({
        ...values,
        role: 'MANAGER',
      });
      setStep(2);
      message.info('Nhập mã xác thực đã được gửi về email');
    } catch (e) {
      console.error(e);
      message.error('Email hoặc mật khẩu không chính xác!');
    }
  }

  async function loginUser() {
    const values = form.getFieldsValue();
    console.log('DEBUG values', values);
    try {
      const response = await Api.init().post('/accounts/login', {
        ...previousFormValues,
        ...values,
      });
      message.info('Đăng nhập thành công');
      console.log('DEBUG response', response);
      localStorage.setItem('token', response.data.data.token);
      localStorage.setItem('user', JSON.stringify(response.data.data.user));
      window.location.href = '/';
    } catch (e) {
      console.error(e);
      message.error('Mã OTP không chính xác!');
    }
  }

  return (
      <div
          style={{
            width: '400px',
            margin: '0 auto 0 auto',
          }}
      >
        <h1
            style={{
              paddingTop: '100px',
            }}
            className={'text-3xl text-center mb-2'}>ĐĂNG NHẬP QUẢN TRỊ</h1>

        <div className="form-container" style={{
          background: '#FFF',
          padding: '25px',
          // marginTop: '15px',
        }}>
          <Form
              labelAlign="left" labelWidth={200}
              form={form}
              layout="vertical"
              requiredMark={false}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            {step === 1 && (
                <>
                  <Form.Item
                      label="Email"
                      name="email"
                      rules={[
                        {
                          required: true,
                          message: 'Trường này là bắt buộc.',
                        }]}
                  >
                    <Input autocomplete={'off'}/>
                  </Form.Item>

                  <Form.Item
                      required={true}
                      label="Mật khẩu"
                      name="password"
                      rules={[
                        {
                          required: true,
                          message: 'Trường này là bắt buộc.',
                        }]}
                  >
                    <Input.Password autocomplete={'off'}/>
                  </Form.Item>
                </>
            )}

            {step === 2 && (
                <Form.Item
                    labelWrap={true}
                    label={
                      <div className={'flex justify-between'}
                      >
                        <span className={'left pr-1'}>Mã xác nhận</span>
                      </div>
                    }
                    required={true}
                    name="login_otp"
                    rules={[
                      {
                        required: true,
                        message: 'Trường này là bắt buộc.',
                      }]}
                >
                  <Input/>
                </Form.Item>
            )}

            <Form.Item>
              <Button block type="primary" htmlType="submit"
                      className={'!bg-green-600 !border-green-600'}>Đăng nhập</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
  );
}
