export default function footer() {
  return (
      <footer
          style={{
            textAlign: 'center',
            paddingBottom: '25px',
          }}
      >
        <p className="text-gray-700 mb-6 lg:mb-0" style={{
          paddingLeft: '160px',
        }}>
          Copyright &copy; {new Date().getFullYear()}{' '}
          <a
              href="https://mohasoftware.com"
              target="_blank"
              rel="noreferrer"
              className="text-green-600 hover:text-green-600"
          >
            <strong>MOHA Software</strong>
          </a>
        </p>
      </footer>
  );
}
