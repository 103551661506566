import axios from 'axios';
import {CONFIG} from '../config';

export const Api = {
  init() {
    if (!window['AXIOS']) {
      window['AXIOS'] = axios.create({
        baseURL: `${CONFIG.apiEndpoint}/api/custom`,
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
    }

    return window['AXIOS'];
  },
};