import {useState} from 'react';
import {NavLink} from 'react-router-dom';
import {FaCarAlt, FaHouseUser, FaListAlt, FaRegFileWord, FaRegWindowMaximize, FaSearchDollar, FaUserFriends} from 'react-icons/fa';

export default function Sidebar() {
  const [showSidebar, setShowSidebar] = useState('-left-40');
  return (
      <>
        {/*<AdminNavbar*/}
        {/*    showSidebar={showSidebar}*/}
        {/*    setShowSidebar={setShowSidebar}*/}
        {/*/>*/}
        <div
            className={`h-screen fixed top-0 md:left-0 ${showSidebar} overflow-y-auto flex-row flex-nowrap overflow-hidden shadow-xl bg-white w-40 z-10 py-1 px-2`}
        >
          <div
              className="flex-col items-stretch min-h-full flex-nowrap px-0 relative">
            <a
                href="/"
                rel="noreferrer"
                className="mt-2 text-center w-full inline-block"
            >
              <div className={'text-center text-2xl text-green-600'}>
                {/*<div className={'pl-3 pr-3'}>*/}
                {/*  <FaHandHoldingUsd size={20} />*/}
                {/*</div>*/}
                <strong>BH Mr Sơn</strong>
              </div>
            </a>
            <div className="flex flex-col">
              <hr className="my-4 min-w-full"/>

              <ul className="flex-col min-w-full flex list-none">
                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/"
                      exact
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaSearchDollar size={20}/>
                    <span>Thống kê</span>
                  </NavLink>
                </li>
                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/car-companies"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaListAlt size={20}/>
                    <span>Hãng xe</span>
                  </NavLink>
                </li>

                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/car-models"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaListAlt size={20}/>
                    <span>Dòng xe</span>
                  </NavLink>
                </li>

                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/staffs"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaHouseUser size={20}/>
                    <span>Nhân viên</span>
                  </NavLink>
                </li>
                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/customers"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaUserFriends size={20}/>
                    <span>Khách hàng</span>
                  </NavLink>
                </li>
                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/vehicles"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaCarAlt size={20}/>
                    <span>Phương tiện</span>
                  </NavLink>
                </li>
                <li className="rounded-lg mb-4">
                  <NavLink
                      to="/contracts"
                      className="flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                      activeClassName="bg-green-600 text-white hover-text-white"
                  >
                    <FaRegFileWord size={20}/>
                    <span>Hợp đồng</span>
                  </NavLink>
                </li>
                <li className="rounded-lg mb-4">
                  <div
                      onClick={() => {
                        localStorage.removeItem('token');
                        localStorage.removeItem('user');
                        window['location'].href = '/login';
                      }}
                      className="cursor-pointer flex items-center gap-4 text-sm px-4 py-3 rounded-xs text-green-600 hover:bg-green-700 hover-text-white"
                  >
                    <FaRegWindowMaximize size={20}/>
                    <span>Đăng xuất</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </>
  );
}
