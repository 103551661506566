import {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';

export default function AdminLayout({children}) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem('token')) {
      setIsLoggedIn(true);
    } else {
      window.location = '/login';
    }
  }, []);

  if (isLoggedIn) {
    return children;
  }

  return 'NULL';
}