import {Button, message, Popconfirm, Space, Table} from 'antd';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {CONFIG} from '../../config';
import {useHistory} from 'react-router-dom';
import {FaPlusCircle} from 'react-icons/fa';

export default function CarCompanyList() {
  const [items, setItems] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [sortField, setSortField] = useState('name');
  const [sortOrder, setSortOrder] = useState('descend');

  const history = useHistory();

  async function handleDelete(id) {
    Api.init().delete('/car-companies/' + id).then(() => {
      message.success('Xóa thành công');
      getData({
        page: 1,
        orderBy: sortField,
        orderType: sortOrder,
      });
    }).catch(() => {
      message.error('Xóa thất bại, vui lòng thử lại!');
    });
  }

  const columns = [
    {
      title: 'Tên',
      dataIndex: 'name',
      key: 'name',
      sorter: true,
    },
    {
      title: 'Thời gian tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (_, record) => {
        return new Date(record['createdAt']).toLocaleString();
      },
    },
    {
      title: 'Thao tác',
      key: 'action',
      width: 150,
      render: (_, record) => (
          <Space size="middle">
            <a onClick={() => {
              history.push('/car-companies/edit/' + record.id);
            }}>Sửa</a>

            <Popconfirm placement="left"
                        title={'Dòng xe của hãng này cũng sẽ bị xóa, bạn chắc chắn chứ'}
                        onConfirm={async () => {
                          await handleDelete(record.id);
                        }} okText="Đồng ý" cancelText="Hủy">
              <a>Xóa</a>
            </Popconfirm>
          </Space>
      ),
    },
  ];

  function getData(params) {
    if (!params.orderType) {
      delete params.orderBy;
    }

    Api.init().get('/car-companies', {params}).then(res => {
      setItems(res.data.data);
      setTotalItems(res.data.total);
    }).catch(() => {
      message.error('Lỗi call API');
      // setIsLoading(false);
    });
  }

  useEffect(() => {
    getData({
      page: currentPage,
      orderBy: sortField,
      orderType: sortOrder,
    });
  }, []);

  const handleTableChange = (
      pagination,
      filters,
      sorter,
  ) => {
    console.log('DEBUG handleTableChange', {
      pagination,
      filters,
      sorter,
    });
    setSortField(sorter.field);
    setSortOrder(sorter.order);

    getData({
      page: pagination.current,
      orderBy: sorter.field,
      orderType: sorter.order,
    });
  };

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>DANH SÁCH</h1>
          <Button onClick={() => {
            history.push('/car-companies/edit');
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaPlusCircle/>
              <div className={'ml-3'}>Tạo mới</div>
            </div>
          </Button>
        </div>
        <div className={'p-5 bg-white mt-5'}>
          <Table
              onChange={handleTableChange}
              sortField={sortField}
              sortOrder={sortOrder}
              // loading={isLoading}
              rowKey={'id'}
              size={'small'}
              dataSource={items}
              columns={columns}
              pagination={{
                position: ['bottomCenter'],
                pageSize: CONFIG.itemPerPage,
                total: totalItems,
                size: 'small',
                current: currentPage,
                onChange: (page, pageSize) => {
                  setCurrentPage(page);
                },
              }}
          />
        </div>
      </div>
  );
}
