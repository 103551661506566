export const CONFIG = {
  itemPerPage: 10,
  // Local
  // imageCdn: 'http://localhost:1337',
  // apiEndpoint: 'http://localhost:1337',
  // Dev
  // imageCdn: 'https://baohiemoto-api.moha.team',
  // apiEndpoint: 'https://baohiemoto-api.moha.team',
  // Prod
  imageCdn: 'https://baohiemoto-api-prod.moha.team',
  apiEndpoint: 'https://baohiemoto-api-prod.moha.team',
  mappingStatusToLabel: {
    APPROVED: 'Đã phê duyệt',
    REJECTED: 'Bị từ chối',
    NEW: 'Chưa khởi tạo',
    DRAFT: 'Bản nháp',
    WAIT_APPROVAL: 'Chờ phê duyệt',
  },
  errorCodes: {
    UNKNOWN: 'Lỗi chưa xác định khi call API',
    NAME_EXISTED: 'Tên đã tồn tại',
    ITEM_NOT_EXISTED: 'Dữ liệu không tồn tại',
    CAR_CONTRACT_BELONG_TO_OTHER_STAFF: 'Xe đã được lập hợp đồng bởi 1 nhân viên khác',
    ACCOUNT_WAS_DISABLED: 'Tài khoản đã bị khóa',
    INVALID_EMAIL_OR_PASSWORD: 'Email hoặc mật khẩu không hợp lệ',
    STAFF_REACH_MAX_VIEW_CAR: 'Bạn đã xem quá số lượng xe cho phép trong ngày. Vui lòng liên hệ admin.',
    EMAIL_EXISTED: 'Email đã tồn tại',
    NO_PERMISSION_TO_ACCESS: 'Không có quyền truy cập, hãy đăng nhập lại',
    OTP_IS_INVALID: 'Mã OTP ko hợp lệ',
  },
  specialYear: 2025
};
