import {message} from 'antd';
import {CONFIG} from './config';

export const HELPER = {
  startLoading: () => {
    window['counterLoading'] = (window['counterLoading'] || 0) + 1;
  },
  endLoading: () => {
    if (window['counterLoading']) {
      window['counterLoading'] = window['counterLoading'] - 1;
    }
  },
  handleApiError: (error) => {
    try {
      message.error(CONFIG.errorCodes[error.response.data.error_code || 'UNKNOWN']);
      if (error.response.data.error_code === 'NO_PERMISSION_TO_ACCESS') {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        window['location'].href = '/login';
      }
    } catch (e) {
      message.error(CONFIG.errorCodes['UNKNOWN']);
    }
    console.error(error);
  },
};
