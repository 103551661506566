import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {Button, Form, Input, message, Select} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {FaArrowCircleLeft, FaSave} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';
import {HELPER} from '../../helper';

export default function CarModelEditOrCreate() {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});
  const [carCompanies, setCarCompanies] = useState([]);

  const [form] = useForm();

  const hookQuery = useHookQuery();

  useEffect(() => {
    if (params.id) {
      Api.init().get('/car-models/' + params.id).then(res => {
        setItem(res.data.data);
        form.setFieldsValue({
          ...res.data.data,
          car_company: res.data.data.car_company ? String(res.data.data.car_company.id) : null,
        });
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    } else {
      form.setFieldsValue({
        car_company: hookQuery.get('carCompany') || null,
      });
    }
  }, [params.id]);

  const onFinish = (values) => {
    if (params.id) {
      Api.init().put('/car-models/' + params.id, values).then(() => {
        message.success('Lưu thành công');
        history.push('/car-models');
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    } else {
      Api.init().post('/car-models', values).then(() => {
        message.success('Tạo thành công');
        history.push('/car-models');
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  function getCarCompanies() {
    Api.init().get('/car-companies', {
      itemPerPage: 1000,
    }).then(res => {
      setCarCompanies(res.data.data);
    }).catch((err) => {
      HELPER.handleApiError(err);
    });
  }

  useEffect(() => {
    getCarCompanies();
  }, []);

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>{params.id ?
              'SỬA THÔNG TIN' :
              'THÊM THÔNG TIN'}</h1>
          <Button onClick={() => {
            history.push('/car-models');
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaArrowCircleLeft/>
              <div className={'ml-3'}>Quay lại</div>
            </div>
          </Button>
        </div>


        <div className="form-container" style={{
          background: '#FFF',
          padding: '25px',
          marginTop: '15px',
        }}>
          <Form
              form={form}
              name="basic"
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="Tên dòng xe"
                name="name"
                rules={[
                  {whitespace: true, message: 'Trường này là bắt buộc.'},
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Input maxLength={20}/>
            </Form.Item>

            <Form.Item
                label="Hãng xe"
                name="car_company"
                rules={[
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Select
                  showSearch
                  optionFilterProp="children"
              >
                {carCompanies.map(item => {
                  return (
                      <Select.Option key={item.id} value={String(item.id)}>{item.name}</Select.Option>
                  );
                })}
              </Select>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button block type="primary" htmlType="submit"
                      className={'!bg-green-600 !border-green-600'}>
                <div className={'flex items-center content-center justify-center'}>
                  <FaSave/>
                  <div className={'ml-1'}>Lưu</div>
                </div>
              </Button>
            </Form.Item>
          </Form>
        </div>
      </div>
  );
}
