import {Button, message} from 'antd';
import {useEffect, useRef} from 'react';
import {CONFIG} from '../config';

export default function ImageGallery({items, onChangeItems, type}) {
  const inputRef = useRef();

  useEffect(() => {
    // inputRef.current.add
  }, []);

  function onFileChange(event) {
    // console.log('DEBUG inputRef.current', inputRef.current.files);
    const newItems = [];
    for (let i = 0; i < inputRef.current.files.length; i++) {
      newItems.push({
        property_type: type,
        inputFile: inputRef.current.files[i],
        localPreviewUrl: URL.createObjectURL(inputRef.current.files[i]),
      });
    }

    onChangeItems([
      ...items,
      ...newItems,
    ]);
  }

  return (
      <div style={{
        overflow: 'hidden',
        display: 'block',
        float: 'left',
      }}>
        <input
            ref={inputRef}
            style={{
              display: 'none',
              visibility: 'hidden',
            }}
            onChange={onFileChange}
            type="file" multiple={true} accept={'image/*'} name={`hidden-gallery-upload-${Date.now()}`}/>

        <div className={'flex items-center'}
             style={{
               flexWrap: 'wrap',
             }}
        >
          {items.map((item, index) => {
            return (
                <div className={'text-center mb-5 mr-2'}
                     style={{
                       width: '50px',
                       display: item.property_type === type ? 'block' : 'none',
                     }}
                >
                  <div className="image-container flex justify-center items-center bg-grey-100" key={item}
                       style={{
                         height: '50px',
                         background: '#cccccc',
                       }}
                  >
                    <a href={item.localPreviewUrl || `${CONFIG.imageCdn}${item.url}`} target={'_blank'}>
                      <img src={item.localPreviewUrl || `${CONFIG.imageCdn}${item.url}`} alt="File" width={50}/>
                    </a>
                  </div>
                  <Button size={'small'} block className={'mr-2 mb-2'}
                          onClick={() => {
                            const newItems = items.filter((item, itemIndex) => index !== itemIndex);
                            onChangeItems(newItems);
                          }}
                  >Xóa</Button>
                </div>
            );
          })}
          <div className="image-container mr-2 float-left" key={'create'}
               style={{
                 paddingBottom: '20px',
               }}
          >
            <Button
                onClick={() => {
                  try {
                    inputRef.current.click();
                  } catch (e) {
                    message.error('Please reload page and try again!');
                  }
                }}
                size={'small'}
            >Thêm mới</Button>
          </div>
        </div>
      </div>
  );
}
