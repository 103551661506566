import 'assets/styles/tailwind.css';

import {Redirect, Route, Switch} from 'react-router-dom';
import Sidebar from 'components/Sidebar';
import Dashboard from 'pages/Dashboard';
import Footer from 'components/Footer';

import StaffList from './pages/Staffs/StaffList';
import StaffEditOrCreate from './pages/Staffs/StaffEditOrCreate';
import Page404 from './pages/Page404';
import CarCompanyList from './pages/CarCompanies/CarCompanyList';
import CarCompanyEditOrCreate from './pages/CarCompanies/CarCompanyEditOrCreate';
import CustomerList from './pages/Customers/CustomerList';
import CustomerEditOrCreate from './pages/Customers/CustomerEditOrCreate';
import ContractList from './pages/Contracts/ContractList';
import VehicleEditOrCreate from './pages/Vehicles/VehicleEditOrCreate';
import VehicleList from './pages/Vehicles/VehicleList';
import AdminLayout from './components/AdminLayout';
import PageLogin from './pages/PageLogin';
import CarModelEditOrCreate from './pages/CarModels/CarModelEditOrCreate';
import CarModelList from './pages/CarModels/CarModelList';
import {useEffect, useState} from 'react';

import {ConfigProvider} from 'antd';
import viVN from 'antd/es/locale/vi_VN';

window['counterLoading'] = 0;

function App() {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    (function() {
      const origOpen = XMLHttpRequest.prototype.open;
      XMLHttpRequest.prototype.open = function() {
        window['counterLoading'] = window['counterLoading'] + 1;
        setIsLoading(true);
        this.addEventListener('load', function() {
          window['counterLoading'] = window['counterLoading'] - 1;
          setIsLoading(window['counterLoading'] > 0);
        });
        this.addEventListener('error', function() {
          window['counterLoading'] = window['counterLoading'] - 1;
          setIsLoading(window['counterLoading'] > 0);
        });
        origOpen.apply(this, arguments);
      };
    })();

    document.addEventListener('keydown', event => {
      // Not allow action while loading
      if (isLoading) {
        event.preventDefault();
        event.stopImmediatePropagation();
        event.stopPropagation();
      }
    });
  }, []);

  return (
      <ConfigProvider
          locale={viVN}
      >
        <div style={{
          position: 'fixed',
          zIndex: 999999999,
        }}>
          {!!isLoading && (
              <div className={'loading-wrapper'}>
                <div className="lds-roller">
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                </div>
              </div>
          )}
        </div>

        <Switch>
          <Route exact path="/login" component={PageLogin}/>
          <Route exact path="/404" component={Page404}/>

          <AdminLayout>
            <Sidebar/>
            <div className="md:ml-40">
              <Route exact path="/" component={Dashboard}/>
              <Route exact path="/staffs" component={StaffList}/>
              <Route exact path="/staffs/edit" component={StaffEditOrCreate}/>
              <Route exact path="/staffs/edit/:id"
                     component={StaffEditOrCreate}/>

              <Route exact path="/customers" component={CustomerList}/>
              <Route exact path="/customers/edit"
                     component={CustomerEditOrCreate}/>
              <Route exact path="/customers/edit/:id"
                     component={CustomerEditOrCreate}/>

              <Route exact path="/contracts" component={ContractList}/>

              <Route exact path="/car-companies" component={CarCompanyList}/>
              <Route exact path="/car-companies/edit"
                     component={CarCompanyEditOrCreate}/>
              <Route exact path="/car-companies/edit/:id"
                     component={CarCompanyEditOrCreate}/>

              <Route exact path="/car-models" component={CarModelList}/>
              <Route exact path="/car-models/edit"
                     component={CarModelEditOrCreate}/>
              <Route exact path="/car-models/edit/:id"
                     component={CarModelEditOrCreate}/>

              <Route exact path="/vehicles" component={VehicleList}/>
              <Route exact path="/vehicles/edit"
                     component={VehicleEditOrCreate}/>
              <Route exact path="/vehicles/edit/:id"
                     component={VehicleEditOrCreate}/>
            </div>
            <Footer/>
          </AdminLayout>
          <Redirect from="*" to="/404"/>
        </Switch>
      </ConfigProvider>
  );
}

export default App;
