import {useHistory, useParams} from 'react-router-dom';
import {useEffect, useState} from 'react';
import {Api} from '../../services/api';
import {Button, Checkbox, Form, Input, message} from 'antd';
import {useForm} from 'antd/es/form/Form';
import {FaArrowCircleLeft} from 'react-icons/fa';
import {useHookQuery} from '../../hooks/use-hook-query';
import {HELPER} from '../../helper';

export default function CustomerEditOrCreate() {
  const history = useHistory();
  const params = useParams();
  const [item, setItem] = useState({});

  const [form] = useForm();

  const hookQuery = useHookQuery();

  useEffect(() => {
    if (params.id) {
      Api.init().get('/customers/' + params.id).then(res => {
        setItem(res.data.data);
        form.setFieldsValue({
          ...res.data.data,
        });
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    }
  }, [params.id]);

  const onFinish = (values) => {
    if (params.id) {
      Api.init().put('/customers/' + params.id, values).then(() => {
        message.success('Lưu thành công');
        history.push('/customers');
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    } else {
      Api.init().post('/customers', values).then(() => {
        message.success('Tạo thành công');
        history.push('/customers');
      }).catch((err) => {
        HELPER.handleApiError(err);
      });
    }
  };

  const onFinishFailed = (errorInfo) => {
    console.log('Failed:', errorInfo);
  };

  return (
      <div className={'p-5'}>
        <div className={'flex items-center justify-between'}>
          <h1 className={'text-3xl mb-0 pr-5'}>{params.id ?
              'SỬA THÔNG TIN' :
              'THÊM THÔNG TIN'}</h1>
          <Button onClick={() => {
            history.push('/customers');
          }} type={'primary'}
                  className={'!bg-green-600 !border-green-600'}>
            <div className={'flex space-between items-center'}>
              <FaArrowCircleLeft/>
              <div className={'ml-3'}>Quay lại</div>
            </div>
          </Button>
        </div>

        <div className="form-container" style={{
          background: '#FFF',
          padding: '25px',
          marginTop: '15px',
        }}>
          <Form
              form={form}
              name="basic"
              labelCol={{span: 8}}
              wrapperCol={{span: 16}}
              initialValues={{}}
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              autoComplete="off"
          >
            <Form.Item
                label="Tên"
                name="name"
                rules={[
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Input/>
            </Form.Item>

            <Form.Item
                label="Email"
                name="email"
                rules={[
                  {
                    required: true,
                    message: 'Trường này là bắt buộc.',
                  }]}
            >
              <Input autocomplete={'off'}/>
            </Form.Item>

            <Form.Item
                label="SĐT"
                name="phone_number"
            >
              <Input/>
            </Form.Item>

            <Form.Item
                valuePropName="checked"
                label="Vô hiệu hóa tài khoản"
                name="is_disabled"
            >
              <Checkbox/>
            </Form.Item>


            <Form.Item
                label="Địa chỉ"
                name="address"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
                label="Ghi chú"
                name="note"
            >
              <Input.TextArea/>
            </Form.Item>

            <Form.Item
                label="Mật khẩu"
                name="password"
            >
              <Input autocomplete={'off'}/>
            </Form.Item>

            <Form.Item
                label="Mật khẩu tạm thời"
                name="temp_password"
            >
              <Input autocomplete={'off'}/>
            </Form.Item>

            <Form.Item wrapperCol={{offset: 8, span: 16}}>
              <Button block type="primary" htmlType="submit"
                      className={'!bg-green-600 !border-green-600'}>Lưu</Button>
            </Form.Item>
          </Form>
        </div>
      </div>
  );
}
